:root {
  --color-white: #f8f8ff;
  --color-blue: #0097e6;
  --color-primary: #007bff;
  --color-dark: #323232;
  --color-light-dark: #4e4e4e;
  --color-secondary: #3157a4;
  --color-success: #289954;
  --color-grey: #E5E4E2;
  --color-light-grey: #A9A9A9;
  --color-danger: #d9534f;
  --color-warning: #ffcc00;
  --color-light-grey2: #dddddd;
  --color-yellow: #ffc107;
  --color-green: #008000;

  --color-brown-01: #D3CAC1;
  --color-brown-02: #BEB4AA;
  --color-brown-03: #B5A89C;
  --color-brown-04: #9D8F84;
  --color-brown-05: #897A70;
  --color-brown-06: #6F6158;

  --color-red-light-01: #D5C1BD;
  --color-red-light-02: #C8AEA9;
  --color-red-light-03: #B69A97;
  --color-red-light-04: #A18583;
  --color-red-light-05: #8E7172;
  --color-red-light-06: #735355;

  --color-green-light-01: #C0E3D9;
  --color-green-light-02: #9ED3C7;
  --color-green-light-03: #82C6B6;
  --color-green-light-04: #66BAAB;
  --color-green-light-05: #46A290;
  --color-green-light-06: #1E8675;

  --color-beige-light-01: #E5CDAA;
  --color-beige-light-02: #D8BF8E;
  --color-beige-light-03: #CFB580;
  --color-beige-light-04: #CBA371;
  --color-beige-light-05: #BF9D5C;
  --color-beige-light-06: #AB8046;


}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-green-light-06) !important;
  color: var(--color-white) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
